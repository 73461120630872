import { CDN_FOLDER_LINK, Cohort, Language } from '~/shared/constants'

const T_PREFIX = 'onboarding.dontExercise'
const IMAGE_PATH = '/images/dont-exercise'

export const getContent = (cohort: Cohort, language: Language) => {
  switch (cohort) {
    case Cohort.MF_BELLY:
      return {
        imgSrc: `${CDN_FOLDER_LINK}/images/dont-exercise-belly-${language}.webp`,
        titleKey: `${T_PREFIX}.mf_belly.title`,
      }
    case Cohort.MF_WATERFASTING:
      return {
        imgSrc: `${CDN_FOLDER_LINK}${IMAGE_PATH}-v2-${language}.webp`,
        titleKey: `${T_PREFIX}.title`,
      }
    case Cohort.MF_SCANNER:
      return {
        imgSrc: `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
        titleKey: `${T_PREFIX}.titleV2`,
      }
    default:
      return {
        imgSrc: `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
        titleKey: `${T_PREFIX}.title`,
      }
  }
}
