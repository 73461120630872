import styled from 'styled-components'

import { Color } from '~/shared/constants'

export const StyledKitCommitV1 = {
  Banner: styled.div`
    aspect-ratio: 1 / 1;
    margin: 0 auto 24px;
    width: 248px;
  `,
  Info: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
  `,
}
