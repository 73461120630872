import React from 'react'

import { useTranslation } from 'react-i18next'

import { Language } from '~/shared/constants'
import { TPageProps } from '~/shared/types'
import { KitCheckboxOptionsView } from '~/shared/views'

import { KIT_OTHER_STRUGGLES_OPTIONS } from './constants'

const TITLE_PATH = 'onboarding.otherStruggles.title'

const OtherStruggles: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(TITLE_PATH)
  const question = t(TITLE_PATH, { lng: Language.EN })

  return (
    <KitCheckboxOptionsView
      title={title}
      question={question}
      options={KIT_OTHER_STRUGGLES_OPTIONS}
      isReverse={false}
      {...props}
    />
  )
}

export default OtherStruggles
