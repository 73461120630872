import cheeseSoupIcon from '~/shared/assets/images/cheese-soup.png'
import chickenSoupIcon from '~/shared/assets/images/chicken-soup.png'
import cupOfMilkIcon from '~/shared/assets/images/cup-of-milk.png'
import saladIcon from '~/shared/assets/images/dinner-vegi-salad.png'

const T_PREFIX = 'onboarding.dinnerTime.options'

export const OPTIONS = [
  {
    value: 'four_six_pm',
    title: `${T_PREFIX}.fourSix`,
    icon: chickenSoupIcon,
  },
  {
    value: 'six_eight_pm',
    title: `${T_PREFIX}.sixEight`,
    icon: cheeseSoupIcon,
  },
  {
    value: 'eight_ten_pm',
    title: `${T_PREFIX}.eightTen`,
    icon: saladIcon,
  },
  {
    value: 'skip',
    title: `${T_PREFIX}.skip`,
    icon: cupOfMilkIcon,
  },
]
