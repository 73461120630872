import styled from 'styled-components'

import label from '~/shared/assets/images/star-green-2.svg'
import { Color } from '~/shared/constants'

export const StyledLowerRisk = {
  Title: styled.h1`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 8px;

    strong {
      font-weight: 700;
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 40px;

    strong {
      font-weight: 700;
      line-height: 22px;
      color: #6fd09a;
    }
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  `,
  ListItem: styled.li`
    position: relative;
    padding-left: 40px;

    &::before {
      content: url(${label});
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
  FixedContainer: styled.div`
    position: fixed;
    width: 100%;
    max-width: 360px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    padding: 0 16px;
  `,
}
