import { lazy } from 'react'

export const PurchaseV1 = lazy(() => import('./v1/PurchaseV1'))
export const PurchaseV2 = lazy(() => import('./v2/PurchaseV2'))
export const PurchaseV3 = lazy(() => import('./v3/PurchaseV3'))
export const PurchaseV4 = lazy(() => import('./v4/PurchaseV4'))
export const PurchaseV5 = lazy(() => import('./v5/PurchaseV5'))
export const KitPurchaseVariant1 = lazy(
  () => import('./kitV1/KitPurchaseVariant1'),
)
export const KitPurchaseVariant2 = lazy(
  () => import('./kitV2/KitPurchaseVariant2'),
)
export const KitPurchaseVariant3 = lazy(
  () => import('./kitV3/KitPurchaseVariant3'),
)
