import React from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { KitPageContainer } from '~/shared/components/PageContainer'
import { PageTitle } from '~/shared/components/PageTitle'
import { Language } from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useNextStep } from '~/shared/hooks/useNextStep'
import {
  selectCurrentVariantCohort,
  selectLanguage,
} from '~/shared/store/common'
import { TPageProps } from '~/shared/types'

import { StyledDontExerciseV1 as S } from './DontExerciseV1.styles'
import { getContent } from './helpers'

const useSelectors = () =>
  useBulkSelector({
    language: selectLanguage,
    cohort: selectCurrentVariantCohort,
  })

const DontExerciseV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { cohort, language } = useSelectors()
  const { titleKey, imgSrc } = getContent(cohort, language)

  const handleContinue = useNextStep({
    pageId,
    question: t(titleKey, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={
        <S.ContinueButtonText>
          <Trans i18nKey="actions.gotIt" />!
        </S.ContinueButtonText>
      }
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={24} textAlign="center">
        <Trans i18nKey={titleKey} />
      </PageTitle>
      <S.ImageContainer>
        <img src={imgSrc} alt="dont-exercise" />
      </S.ImageContainer>
    </KitPageContainer>
  )
}

export default DontExerciseV1
