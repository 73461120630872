import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import alarmClockImg from '~/shared/assets/images/alarm-clock.png'
import coloredChartImg from '~/shared/assets/images/colored-chart.png'
import pastaImg from '~/shared/assets/images/pasta.png'
import saladImg from '~/shared/assets/images/salad.png'
import scalesImg from '~/shared/assets/images/scales.png'
import waterImg from '~/shared/assets/images/water-icon.png'
import { Language } from '~/shared/constants'
import { TPageProps } from '~/shared/types'
import { KitCheckboxOptionsView } from '~/shared/views'

const TITLE_T_KEY = 'onboarding.nutrimateHelp.title'
const OPTIONS_T_KEY = 'onboarding.nutrimateHelp.options'

const OPTIONS = [
  {
    textPath: `${OPTIONS_T_KEY}.counting`,
    value: 'calorie-counting',
    imageUrl: scalesImg,
  },
  {
    textPath: `${OPTIONS_T_KEY}.tips`,
    value: 'healthy-eating-tips',
    imageUrl: saladImg,
  },
  {
    textPath: `${OPTIONS_T_KEY}.analysis`,
    value: 'calorie-intake-analysis',
    imageUrl: coloredChartImg,
  },
  {
    textPath: `${OPTIONS_T_KEY}.insights`,
    value: 'my-food-pattern-insights',
    imageUrl: pastaImg,
  },
  {
    textPath: `${OPTIONS_T_KEY}.intermittent`,
    value: 'intermittent-fasting',
    imageUrl: alarmClockImg,
  },
  {
    textPath: `${OPTIONS_T_KEY}.fasting`,
    value: 'water-fasting',
    imageUrl: waterImg,
  },
]

const NutrimateHelp: FC<TPageProps> = ({ nextPagePath, ...props }) => {
  const { t } = useTranslation()

  return (
    <KitCheckboxOptionsView
      {...props}
      nextPagePath={nextPagePath}
      isReverse
      title={t(TITLE_T_KEY)}
      question={t(TITLE_T_KEY, { lng: Language.EN })}
      borderRadius="16px"
      spacingBetweenCheckboxAndContent="0 16px 0 16px"
      options={OPTIONS}
    />
  )
}

export default NutrimateHelp
