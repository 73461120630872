import React from 'react'

import { useTranslation } from 'react-i18next'

import { Language } from '~/shared/constants'
import { TPageProps } from '~/shared/types'
import { KitRadioOptionsView } from '~/shared/views'

import { FEEL_GUILTY_V1_OPTIONS } from './constants'

const T_PREFIX = 'onboarding.feelGuiltyV1'

export const FeelGuiltyV1: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(`${T_PREFIX}.title`)
  const question = t(`${T_PREFIX}.title`, { lng: Language.EN })

  return (
    <KitRadioOptionsView
      title={title}
      options={FEEL_GUILTY_V1_OPTIONS}
      question={question}
      isReverse={false}
      {...props}
    />
  )
}

export default FeelGuiltyV1
