import { lazy } from 'react'

export const CheckoutVariant1 = lazy(() => import('./v1/CheckoutVariant1'))
export const CheckoutVariant2 = lazy(() => import('./v2/CheckoutVariant2'))
export const KitCheckoutVariant1 = lazy(
  () => import('./kitV1/KitCheckoutVariant1'),
)
export const KitCheckoutV3 = lazy(() => import('./kitV3/KitCheckoutV3'))
export const KitCheckoutV4 = lazy(() => import('./kitV4/KitCheckoutV4'))
export const CheckoutModal = lazy(() => import('./kitV2/CheckoutModal'))
