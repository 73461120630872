import { lazy } from 'react'

export const OptionsLeftToRight = lazy(() => import('./OptionsLeftToRight'))
export const OptionsRightToLeft = lazy(() => import('./OptionsRightToLeft'))
export const CustomOptionsRightToLeft = lazy(
  () => import('./CustomOptionsRightToLeft'),
)
export const OptionsLeftToRightV2 = lazy(() => import('./OptionsLeftToRightV2'))
export const OptionsLeftToRightV3 = lazy(() => import('./OptionsLeftToRightV3'))
export const OptionsRightToLeftV3 = lazy(() => import('./OptionsRightToLeftV3'))
export const ImageOptionsV1 = lazy(() => import('./ImageOptionsV1'))
export const ImageWithTextV1 = lazy(() => import('./ImageWithTextV1'))
export const InfluencerProfile = lazy(
  () => import('./InfluencerProfile/InfluencerProfile'),
)
export const ImageCenterOptionsBelow = lazy(
  () => import('./ImageCenterOptionsBelow'),
)
