import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import bannerMen from '~/shared/assets/images/commit-men.png'
import bannerWomen from '~/shared/assets/images/commit-women.png'
import { KitPageContainer } from '~/shared/components/PageContainer'
import { PageTitle } from '~/shared/components/PageTitle'
import { Gender, Language } from '~/shared/constants'
import { useNextStep } from '~/shared/hooks/useNextStep'
import { selectCurrentUserGender } from '~/shared/store/common'
import { TPageProps } from '~/shared/types'

import { StyledKitCommitV1 as S } from './KitCommitV1.styles'

const T_PREFIX = 'onboarding.kitCommitV1'

const KitCommitV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const imgSrc = gender === Gender.MALE ? bannerMen : bannerWomen

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={t('actions.commitToTry')}
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.Banner>
        <img src={imgSrc} alt="commit-banner" />
      </S.Banner>
      <PageTitle marginBottom={16} textAlign="left">
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
      <S.Info>
        <Trans i18nKey={`${T_PREFIX}.info`} />
      </S.Info>
    </KitPageContainer>
  )
}

export default KitCommitV1
