import React from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from '~/shared/components/AnswerButton'
import { Container } from '~/shared/components/Container'
import { Option } from '~/shared/components/Option'
import { PageTitle } from '~/shared/components/PageTitle'
import { Language, OptionType } from '~/shared/constants'
import { useDelayedContinue } from '~/shared/hooks/useDelayedContinue'
import { useNextStep } from '~/shared/hooks/useNextStep'
import { TPageProps } from '~/shared/types'

import { OPTIONS } from './constants'

const T_PREFIX = 'onboarding.dinnerTime'

const DinnerTimeV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <Container>
      <PageTitle marginBottom={24}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
      {OPTIONS.map(({ value, title, icon }) => (
        <Option {...optionProps} disabled={isAnswersDisabled} value={value}>
          <AnswerButton iconSrc={icon}>
            <h3>
              <Trans i18nKey={title} />
            </h3>
          </AnswerButton>
        </Option>
      ))}
    </Container>
  )
}

export default DinnerTimeV1
